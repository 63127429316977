import type { StyleRule } from '@archipro-design/aria';
import { captionClassName, listItemClassName } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const ProductList: StyleRule = ({ theme: { siteVariables } }) => ({
    marginTop: pxToRem(15),
    borderTopWidth: '0 !important',

    [`& .${listItemClassName}`]: {
        display: 'grid',
        gridTemplateColumns: '0.3fr 0.7fr',
        borderTopWidth: 0,
        borderBottom: '1px solid #d2d2d0',

        [`& > .${captionClassName}`]: {
            paddingLeft: pxToRem(1),
            '&:first-child': {
                display: 'block',

                [`& .${captionClassName}`]: {
                    height: '100%',
                    display: 'block',
                    color: siteVariables.colors.charcoal[100],
                    fontSize: siteVariables.fontSizes.caption04,
                    letterSpacing: siteVariables.letterSpacingNormal,
                    lineHeight: 1.5,
                },
            },
        },
    },
});

export const AttributeOptions: StyleRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
    paddingBottom: pxToRem(15),
    paddingTop: pxToRem(22),
    textAlign: 'end',
});
