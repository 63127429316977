import { List, useStyles, useTheme } from '@archipro-design/aria';
import React from 'react';
import * as S from './InfoSection.style';
import classNames from 'classnames';

const InfoSectionMobile = ({
    label,
    children,
    showMoreText,
    showLessText,
    lineLimit,
    className,
    rightSectionClassName,
}: {
    label: string;
    children: React.ReactNode;
    showMoreText?: string;
    showLessText?: string;
    lineLimit?: number;
    className?: string;
    rightSectionClassName?: string;
}) => {
    const { css } = useStyles();
    const theme = useTheme();
    return (
        <List
            items={[
                {
                    label: label,
                    customChildComponent: (
                        <div
                            className={classNames(
                                rightSectionClassName,
                                css(S.AttributeOptions)
                            )}
                        >
                            {children}
                        </div>
                    ),
                },
            ]}
            className={classNames(className, css(S.ProductList))}
            variables={{
                listBorderColor: theme.siteVariables.colors.grey[125],
            }}
            showLessText={showLessText}
            showMoreText={showMoreText}
            lineLimit={lineLimit}
        />
    );
};

export default InfoSectionMobile;
